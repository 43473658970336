<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key" />

    <tabbar v-if="$route.meta.showTab&&!isNew" />
    <tabbar-new v-if="$route.meta.showTab && isNew" />
    <loading />
  </div>
</template>

<script>
import Tabbar from './components/Tabbar/index.vue'
import TabbarNew from './components/Tabbar/indexNew.vue'
import { fpjsGetVisitorDataExtendedMixin } from '@fingerprintjs/fingerprintjs-pro-vue-v2'
import Loading from './components/Loading'
export default {
  components: {
    Tabbar,
    TabbarNew,
    Loading
  },
  mixins: [fpjsGetVisitorDataExtendedMixin],
  data() {
    return {
      isNew: null
    }
  },
  computed: {
    key() {
      return this.$route.fullPath
    }
  },
  async created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    this.$bus.$on('changeBottomTab', v => {
      this.isNew = v
      this.$i18n.locale = localStorage.lang || 'cn'
    })
    await this.$getVisitorDataExtended()
    const visitorId = this.visitorDataExtended?.data?.visitorId
    localStorage.setItem('visitorId', visitorId)
    // if (window.location.hash.includes('new')) {
    //   this.isNew = true
    // } else {
    //   this.isNew = false
    // }
  },
}
</script>
<style scoped>
  #app {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    background: #151e34;
    /* background: #f5f5f5; */
  }
</style>
