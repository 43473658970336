<template>
  <div>
    <div class="tabbarNew">
      <div class="customer-service-new" @click="showService=true" />
      <div class="mons-change-new" @click="$router.push('/setMonsType')" />
      <van-tabbar v-model="active" :fixed="false" route>
        <van-tabbar-item v-for="(item ,index) in menuList" :key="index" :class="item.url===$route.path?'van-tabbar-item--active':''" :style="index===2?'margin-bottom:30px':''" @click="goTo(item.url,index)">{{ item.name }}
          <template #icon="props">
            <svg-icon :icon-class="index===0?(showMenuList?item.iconActive:item.iconInactive):(item.url===$route.path ? item.iconActive : item.iconInactive)" :width="item.url==='/shareFriends'?40:20" :height="item.url==='/shareFriends'?40:20" />
          </template>
        </van-tabbar-item>

      </van-tabbar>
    </div>
    <van-popup v-model="showService">
      <customer-service @close="showService=false" />
    </van-popup>
    <van-popup v-model="showMenuList" position="bottom" :overlay="false">
      <menu-list @close="showMenuList=false;" />
    </van-popup>
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'
import CustomerService from '@/views/portal/modules/CustomerService.vue'
import MenuList from '@/views/portal/modules/MenuList.vue'
export default {
  components: {
    CustomerService,
    MenuList
  },
  data() {
    return {
      showService: false,
      showMenuList: false,
      active: 0,
      menuList: [
        { url: '/menuList', iconActive: '菜单_s', iconInactive: '菜单_n', name: this.$t('bottomTab.zmk07') },
        { url: '/home', iconActive: '首页_s1', iconInactive: '首页_n1', name: this.$t('bottomTab.zmk01') },
        { url: '/shareFriends', iconActive: '推荐_s', iconInactive: '推荐', name: this.$t('bottomTab.zmk06') },
        { url: '/activity', iconActive: '活动_s1', iconInactive: '活动_n1', name: this.$t('bottomTab.zmk02') },
        { url: '/user', iconActive: '我的_s1', iconInactive: '我的_n1', name: this.$t('bottomTab.zmk04') }
      ]
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  mounted() {
    this.$bus.$on('showMenuPop', (data) => {
      this.showMenuList = data
      // if (this.showMenuList) {
      //   this.menuList[0].url = '/home'
      //   this.menuList[1].url = '/menuList'
      // } else {
      //   this.menuList[0].url = '/menuList'
      //   this.menuList[1].url = '/home'
      // }
    })
    this.$bus.$on('showCustomer', (data) => {
      this.showService = data
    })
  },
  methods: {
    goTo(url, index) {
      if (index === 1 || index === 3 || index === 4) {
        this.$store.getters.token ? (this.$router.push({ path: url })) : this.$toast('请登录')
        this.showMenuList = false
        if (index === 1) {
          this.$bus.$emit('returnHot')
        }
        // if (this.showMenuList) {
        //   this.menuList[0].url = '/home'
        //   this.menuList[1].url = '/menuList'
        // } else {
        //   this.menuList[0].url = '/menuList'
        //   this.menuList[1].url = '/home'
        // }
      } else if (index === 0) {
        this.showMenuList = !this.showMenuList
        // if (this.showMenuList) {
        //   this.menuList[0].url = '/home'
        //   this.menuList[1].url = '/menuList'
        // } else {
        //   this.menuList[0].url = '/menuList'
        //   this.menuList[1].url = '/home'
        // }
      } else {
        this.$router.push({ path: url })
        this.showMenuList = false
        // if (this.showMenuList) {
        //   this.menuList[0].url = '/home'
        //   this.menuList[1].url = '/menuList'
        // } else {
        //   this.menuList[0].url = '/menuList'
        //   this.menuList[1].url = '/home'
        // }
      }
    },
    // goUser() {
    //   this.store.getters.token ? (this.$router.push({ path: '/user' }), this.selected = true) : this.$toast('请登录')
    // },
    showTip() {
      this.$toast('敬请期待')
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbarNew {
  width: 100vw;
  position: fixed;
  bottom: -40px;
  left: 0;
  z-index: 9999999;
  // border-top: 2px solid #f5f5f5;
}
</style>
<style lang="less">
.customer-service-new{
  bottom:150px;
  right: 0;
  width: 112px;
  height: 112px;
  display: flex;
  position: fixed!important;
  z-index: 999;
  background-image: url('~@/assets/portal/联系客服.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.mons-change-new{
  bottom:280px;
  right: 0;
  width: 112px;
  height: 112px;
  display: flex;
  position: fixed!important;
  z-index: 999;
  background-image: url('~@/assets/portal/筹码切换.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.tabbarNew {
  .van-tabbar {
    background: url('~@/assets/portal/bg_底部导航1.png') repeat-y top;
    background-size: 100%;
    border: none;
    height:200px;
  }
  .van-tabbar-item {
    color: #c1c9e6;
  }
  .van-tabbar-item--active {
    color: #fff;
    background-color: unset;
  }
  [class*=van-hairline]::after {
    border: none;
  }
}
::-webkit-scrollbar{
  width: 10px;
  height: 10px;
  background: #013b8e;
}
// 滚动条
::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(0, 0, 0, 0);
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: inherit;
  // background-color: rgba(144, 147, 153, 0.3);
  background-color: rgba(144, 147, 153, 0);
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color;
}
</style>
