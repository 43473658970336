
import { login, logout, getInfo, getLogo } from '@/api/user'
import { drawinfos } from '@/api/safe'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
  token: getToken(),
  id: '',
  name: '',
  userInfo: {},
  sysInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info
    state.id = info.id
    state.name = info.nickname
  },
  SET_SYS_INFO: (state, info) => {
    state.sysInfo = info
  }
}

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { loginname, loginpwd } = userInfo
    return new Promise((resolve, reject) => {
      login({ loginname, loginpwd })
        .then(async res => {
          const token = res.suid
          await commit('SET_TOKEN', token)
          setToken(token)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 登出
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      // logout()
      //   .then(() => {
      commit('SET_TOKEN', '')
      removeToken()

      resolve()
      // })
      // .catch(error => {
      //   reject(error)
      // })
    })
  },

  // 获取基本用户信息
  getInfo({ commit, state }) {
    debugger
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(res => {
          // const data = res.map
          // const res1 = await drawinfos()
          debugger
          const data = { mons: res.mons && Number(res.mons).toFixed(2) || '', ucode: res.ucode, uname: res.uname, umail: res.email }
          if (!data) {
            reject(new Error('获取基本信息失败，请重新登录'))
          }
          commit('SET_USER_INFO', data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSysInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getLogo(state.token)
        .then(res => {
          const data = res
          // const data = res.map
          // const res1 = await drawinfos()
          // const data = { mons: res.mons && Number(res.mons).toFixed(2) || '', ucode: res.ucode, uname: res.uname, umail: res.email }
          // if (!data) {
          //   reject(new Error('获取基本信息失败，请重新登录'))
          // }
          commit('SET_SYS_INFO', data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 重置token
  resetToken({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token)
      // removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
